import { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { themeSettings } from "./theme";
import { setUserId } from './store/globalSlice';
import CircleLoading from "./components/global/circleloading";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Close } from "@mui/icons-material";
import { SocketProvider } from "./hooks/socketConnect";


const Iframe = lazy(() => import("./views/screen/iframe/iframe"));
const AllDoctorFrame = lazy(() => import('./views/screen/iframe/alldoctorsframe'));
const DoctorFrame = lazy(() => import('./views/screen/iframe/doctorframe'));
const Department = lazy(() => import('./views/hospital/department'));
const Radiology = lazy(() => import('./views/hospital/radiology'));

const Login = lazy(() => import('./views/login/login'));
const Loginotp = lazy(() => import('./views/login/loginotp'));
const PassVerify = lazy(() => import('./views/login/passverify'));
const Signup = lazy(() => import('./views/login/signup'));
const Verify = lazy(() => import('./views/login/verify'));
const Forgetotp = lazy(() => import('./views/login/forgetotp'));
const ForgetVerify = lazy(() => import('./views/login/forgetverify'));
const Reset = lazy(() => import('./views/login/reset'));

const DocLogin = lazy(() => import('./views/doctor/login/login'));
const DocLoginotp = lazy(() => import('./views/doctor/login/loginotp'));
const DocPassVerify = lazy(() => import('./views/doctor/login/passverify'));
const DocForgetotp = lazy(() => import('./views/doctor/login/forgetotp'));
const DocForgetVerify = lazy(() => import('./views/doctor/login/forgetverify'));
const DocReset = lazy(() => import('./views/doctor/login/reset'));

const HosLogin = lazy(() => import('./views/hospital/login/login'));
const HosLoginotp = lazy(() => import('./views/hospital/login/loginotp'));
const HosPassVerify = lazy(() => import('./views/hospital/login/passverify'));
const HosForgetotp = lazy(() => import('./views/hospital/login/forgetotp'));
const HosForgetVerify = lazy(() => import('./views/hospital/login/forgetverify'));
const HosReset = lazy(() => import('./views/hospital/login/reset'));

const Main = lazy(() => import('./views/main'));

const Home = lazy(() => import('./views/screen/home'));
const Search = lazy(() => import('./views/screen/search'));
const Appointment = lazy(() => import('./views/screen/appointment'));
const History = lazy(() => import('./views/screen/history'));
const Account = lazy(() => import('./views/screen/account'));
const Profile = lazy(() => import('./views/screen/profile'));
const AllDoctors = lazy(() => import('./views/screen/alldoctors'));
const Doctor = lazy(() => import('./views/screen/doctor'));
const AppDetails = lazy(() => import('./views/screen/appdetails'));
const PatientForm = lazy(() => import('./views/screen/patientform'));

const AppointmentFrame = lazy(() => import('./views/screen/iframe/appointmentframe'));
const PatientFormFrame = lazy(() => import('./views/screen/iframe/patientformframe'));

const DocHome = lazy(() => import('./views/doctor/dochome'));
const Liveappointment = lazy(() => import('./views/doctor/liveappointment'));
const DocHistory = lazy(() => import('./views/doctor/dochistory'));
const CheckAppointment = lazy(() => import('./views/doctor/checkappointment'));
const Patient = lazy(() => import('./views/doctor/patient'));
const DocAccount = lazy(() => import('./views/doctor/docaccount'));
const DocProfile = lazy(() => import('./views/doctor/docprofile'));

const HosHome = lazy(() => import('./views/hospital/hoshome'));
const HosAppointment = lazy(() => import('./views/hospital/hosappointment'));
const SwapAppointment = lazy(() => import('./views/hospital/swapapp'));
const AddDoctor = lazy(() => import('./views/hospital/adddoctor'));
const EditDoctor = lazy(() => import('./views/hospital/editdoctor'));
const AddNewSlots = lazy(() => import('./views/hospital/addnewslots'));
const HosAllDoctors = lazy(() => import('./views/hospital/hosalldoctors'));
const AddEmployee = lazy(() => import('./views/hospital/addemployee'));
const HosHistory = lazy(() => import('./views/hospital/hoshistory'));
const HosAccount = lazy(() => import('./views/hospital/hosaccount'));
const Activity = lazy(() => import('./views/hospital/activity'));
const ViewReport = lazy(() => import('./views/hospital/viewreport'));
const HosProfile = lazy(() => import('./views/hospital/hosprofile'));
const EPatientForm = lazy(() => import('./views/hospital/patientform'));

const Notification = lazy(() => import('./views/screen/notification'));

function App() {

  const center = useSelector((state) => state.global.center);
  const theme = useMemo(() => createTheme(themeSettings("light")));

  //const mainurl = "http://localhost/docapp/main/";
  const mainurl = "https://appointment.motherlandhospital.com/docapp/main/";

  if (center.payload === "main") {
    //var baseurl = "http://localhost/docapp/main/";
    var baseurl = "https://appointment.motherlandhospital.com/docapp/main/";
  }
  if (center.payload === "center1") {
    //var baseurl = "http://localhost/docapp/center1/";
    var baseurl = "https://appointment.motherlandhospital.com/docapp/gaurcity/";
  }
  if (center.payload === "center2") {
    //var baseurl = "http://localhost/docapp/center2/";
    var baseurl = "https://appointment.motherlandhospital.com/docapp/ekmurti/";
  }

  const paymentmode = false;
  const token = useSelector((state) => state.global.userId);
  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [role, setRole] = useState("user");

  const callAuthApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${token.payload}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders
    };


    await fetch(baseurl + "auth/auth-file.php", requestOptions)
      .then(response => response.json())
      .then((result) => {
        if (result.status) {
          setUser(result.data.user);
          setRole(result.data.role);
        }
        else {
          dispatch(setUserId(null));
          <Navigate to="/login" />

        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    callAuthApi();
  }, [])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          style={{ marginTop: "50px", fontSize: { xs: "12px", md: "14px" } }}
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          preventDuplicate={true}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <Close fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          <BrowserRouter>
            <Routes>
              {!token.payload && <Route path="/*" element={<Navigate to="/login" replace />} />}

              <Route exact path="/login" element={<Suspense fallback={<CircleLoading />}><Login baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/login-otp" element={<Suspense fallback={<CircleLoading />}><Loginotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/pass-verify" element={<Suspense fallback={<CircleLoading />}><PassVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/signup" element={<Suspense fallback={<CircleLoading />}><Signup baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/verify" element={<Suspense fallback={<CircleLoading />}><Verify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/forget" element={<Suspense fallback={<CircleLoading />}><Forgetotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/forget-verify" element={<Suspense fallback={<CircleLoading />}><ForgetVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/reset" element={<Suspense fallback={<CircleLoading />}><Reset baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />

              <Route exact path="/doctor/login" element={<Suspense fallback={<CircleLoading />}><DocLogin baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/doctor/login-otp" element={<Suspense fallback={<CircleLoading />}><DocLoginotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/doctor/pass-verify" element={<Suspense fallback={<CircleLoading />}><DocPassVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/doctor/forget" element={<Suspense fallback={<CircleLoading />}><DocForgetotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/doctor/forget-verify" element={<Suspense fallback={<CircleLoading />}><DocForgetVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/doctor/reset" element={<Suspense fallback={<CircleLoading />}><DocReset baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />

              <Route exact path="/employee/login" element={<Suspense fallback={<CircleLoading />}><HosLogin baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/employee/login-otp" element={<Suspense fallback={<CircleLoading />}><HosLoginotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/employee/pass-verify" element={<Suspense fallback={<CircleLoading />}><HosPassVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/employee/forget" element={<Suspense fallback={<CircleLoading />}><HosForgetotp baseurl={mainurl} setData={setUser} setRole={setRole} /></Suspense>} />
              <Route exact path="/employee/forget-verify" element={<Suspense fallback={<CircleLoading />}><HosForgetVerify baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />
              <Route exact path="/employee/reset" element={<Suspense fallback={<CircleLoading />}><HosReset baseurl={mainurl} setData={setUser} user={user} setRole={setRole} /></Suspense>} />

              <Route exact path="/iframe" element={<Suspense fallback={<CircleLoading />}><Iframe data={user} url={mainurl} role={role} /></Suspense>} >
                <Route exact path="appointment/:id" element={<Suspense fallback={<CircleLoading />}><AppointmentFrame /></Suspense>} />
                <Route exact path="patientform" element={<Suspense fallback={<CircleLoading />}><PatientFormFrame setData={setUser} setRole={setRole} /></Suspense>} />
                <Route exact path="alldoctors" element={<Suspense fallback={<CircleLoading />}><AllDoctorFrame role={role} /></Suspense>} />
                <Route exact path="doctor/:id" element={<Suspense fallback={<CircleLoading />}><DoctorFrame /></Suspense>} />
              </Route>

              <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Main data={user} url={baseurl} mainurl={mainurl} role={role} paymentmode={paymentmode} /></Suspense>} >
                {role === "user" &&
                  <>
                    <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Home /></Suspense>} />
                    <Route exact path="/search/:text" element={<Suspense fallback={<CircleLoading />}><Search /></Suspense>} />
                    <Route exact path="/search" element={<Suspense fallback={<CircleLoading />}><Search /></Suspense>} />
                    <Route exact path="/appointment" element={<Suspense fallback={<CircleLoading />}><Appointment /></Suspense>} />
                    <Route exact path="/alldoctors" element={<Suspense fallback={<CircleLoading />}><AllDoctors role={role} /></Suspense>} />
                    <Route exact path="/doctor/:id" element={<Suspense fallback={<CircleLoading />}><Doctor /></Suspense>} />
                    <Route exact path="/patientform" element={<Suspense fallback={<CircleLoading />}><PatientForm setData={setUser} setRole={setRole} /></Suspense>} />
                  </>
                }
                {token.payload &&
                  <>
                    {role === "doctor" &&
                      <>
                        <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><DocHome /></Suspense>} />
                        <Route exact path="/appointment" element={<Suspense fallback={<CircleLoading />}><CheckAppointment /></Suspense>} />
                        <Route exact path="/liveappointment" element={<Suspense fallback={<CircleLoading />}><Liveappointment /></Suspense>} />
                        <Route exact path="/appointment/:id" element={<Suspense fallback={<CircleLoading />}><Patient role={role} /></Suspense>} />
                        <Route exact path="/history" element={<Suspense fallback={<CircleLoading />}><DocHistory /></Suspense>} />
                        <Route exact path="/account" element={<Suspense fallback={<CircleLoading />}><DocAccount /></Suspense>} />
                        <Route exact path="/account/profile" element={<Suspense fallback={<CircleLoading />}><DocProfile /></Suspense>} />
                      </>
                    }

                    {role === "employee" &&
                      <>
                        <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><SocketProvider><HosHome /></SocketProvider></Suspense>} />
                        <Route exact path="/addnewslots" element={<Suspense fallback={<CircleLoading />}><AddNewSlots /></Suspense>} />
                        <Route exact path="/appointment/:id" element={<Suspense fallback={<CircleLoading />}><Patient role={role} /></Suspense>} />
                        <Route exact path="/swapapp/:id" element={<Suspense fallback={<CircleLoading />}><SwapAppointment role={role} /></Suspense>} />
                        <Route exact path="/viewalldoctors" element={<Suspense fallback={<CircleLoading />}><HosAllDoctors /></Suspense>} />
                        <Route exact path="/adddoctor" element={<Suspense fallback={<CircleLoading />}><AddDoctor /></Suspense>} />
                        <Route exact path="/addemployee" element={<Suspense fallback={<CircleLoading />}><AddEmployee /></Suspense>} />
                        <Route exact path="/adddepartment" element={<Suspense fallback={<CircleLoading />}><Department /></Suspense>} />
                        <Route exact path="/addradiology" element={<Suspense fallback={<CircleLoading />}><Radiology /></Suspense>} />
                        <Route exact path="/editdoctor/:id" element={<Suspense fallback={<CircleLoading />}><EditDoctor /></Suspense>} />
                        <Route exact path="/history" element={<Suspense fallback={<CircleLoading />}><HosHistory /></Suspense>} />
                        <Route exact path="/allactivity" element={<Suspense fallback={<CircleLoading />}><Activity /></Suspense>} />
                        <Route exact path="/viewreport" element={<Suspense fallback={<CircleLoading />}><ViewReport /></Suspense>} />
                        <Route exact path="/account" element={<Suspense fallback={<CircleLoading />}><HosAccount role={role} /></Suspense>} />
                        <Route exact path="/account/profile" element={<Suspense fallback={<CircleLoading />}><HosProfile /></Suspense>} />

                        <Route exact path="/search" element={<Suspense fallback={<CircleLoading />}><Search /></Suspense>} />
                        <Route exact path="/appointment" element={<Suspense fallback={<CircleLoading />}><HosAppointment role={role} /></Suspense>} />
                        <Route exact path="/alldoctors" element={<Suspense fallback={<CircleLoading />}><AllDoctors role={role} /></Suspense>} />
                        <Route exact path="/doctor/:id" element={<Suspense fallback={<CircleLoading />}><Doctor /></Suspense>} />
                        <Route exact path="/patientform" element={<Suspense fallback={<CircleLoading />}><EPatientForm role={role} /></Suspense>} />
                      </>
                    }

                    {role === "staff" &&
                      <>
                        <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><HosHome /></Suspense>} />
                        <Route exact path="/addnewslots" element={<Suspense fallback={<CircleLoading />}><AddNewSlots /></Suspense>} />
                        <Route exact path="/appointment/:id" element={<Suspense fallback={<CircleLoading />}><Patient role={role} /></Suspense>} />
                        <Route exact path="/swapapp/:id" element={<Suspense fallback={<CircleLoading />}><SwapAppointment /></Suspense>} />
                        <Route exact path="/viewalldoctors" element={<Suspense fallback={<CircleLoading />}><HosAllDoctors /></Suspense>} />
                        <Route exact path="/history" element={<Suspense fallback={<CircleLoading />}><HosHistory /></Suspense>} />
                        <Route exact path="/account" element={<Suspense fallback={<CircleLoading />}><HosAccount role={role} /></Suspense>} />
                        <Route exact path="/account/profile" element={<Suspense fallback={<CircleLoading />}><HosProfile /></Suspense>} />

                        <Route exact path="/search" element={<Suspense fallback={<CircleLoading />}><Search /></Suspense>} />
                        <Route exact path="/appointment" element={<Suspense fallback={<CircleLoading />}><HosAppointment /></Suspense>} />
                        <Route exact path="/alldoctors" element={<Suspense fallback={<CircleLoading />}><AllDoctors role={role} /></Suspense>} />
                        <Route exact path="/doctor/:id" element={<Suspense fallback={<CircleLoading />}><Doctor /></Suspense>} />
                        <Route exact path="/patientform" element={<Suspense fallback={<CircleLoading />}><EPatientForm /></Suspense>} />
                      </>
                    }

                    <Route exact path="/history" element={<Suspense fallback={<CircleLoading />}><History /></Suspense>} />
                    <Route exact path="/appdetails/:id" element={<Suspense fallback={<CircleLoading />}><AppDetails /></Suspense>} />
                    <Route exact path="/account" element={<Suspense fallback={<CircleLoading />}><Account /></Suspense>} />
                    <Route exact path="/account/profile" element={<Suspense fallback={<CircleLoading />}><Profile /></Suspense>} />
                    <Route exact path="/notification" element={<Suspense fallback={<CircleLoading />}><Notification role={role} /></Suspense>} />
                  </>
                }
                <Route exact path="/*" element={<Suspense fallback={<CircleLoading />}>404 Page not Found</Suspense>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </div >
  );
}

export default App;
