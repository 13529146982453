
// color design tokens export
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
            primary: "#f5f5f5",
            secondary: "#00998C",
            ternary: "#fff",
            background: "#00332f",
            paper: "#003330",
        }
        : {
            primary: "#19a68d",
            secondary: "#2C3333",
            ternary: "#fff",
            background: "#f5f5f5",
            paper: "#fcfcfc",
        }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // palette values for dark mode
                    primary: {
                        main: colors.primary,
                    },
                    secondary: {
                        main: colors.secondary,
                    },
                    ternary: {
                        main: colors.ternary,
                    },
                    background: {
                        default: colors.background,
                        paper: colors.paper,
                    },
                }
                : {
                    // palette values for light mode
                    primary: {
                        main: colors.primary,
                    },
                    secondary: {
                        main: colors.secondary,
                    },
                    ternary: {
                        main: colors.ternary,
                    },
                    background: {
                        default: colors.background,
                        paper: colors.paper,
                    },
                }),
        },
        typography: {
            h2: {
                fontSize: 38,
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif"
            },
            h3: {
                fontSize: 28,
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif"
            },
            h4: {
                fontSize: 22,
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif"
            },
            h5: {
                fontSize: 18,
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif"
            },
            h6: {
                fontSize: 16,
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif"
            },
            h7: {
                fontSize: 15,
                fontFamily: "Poppins, sans-serif"
            },
            p: {
                fontSize: 13,
                fontFamily: "Poppins, sans-serif"
            },
            button: {
                fontFamily: "Poppins, sans-serif"
            }
        }
    };
};

