import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    center: { type: 'DocAPP/setCenter', payload: "main" },
    userId: "null",
    name: "null",
    hiddenColumn: { type: "DocAPP/setHiddenColumn", payload: { id: false } }
};

export const globalSlice = createSlice({
    name: "DocAPP",
    initialState,
    reducers: {
        setCenter: (state, center) => {
            state.center = center;
        },
        setUserId: (state, userId) => {
            state.userId = userId;
        },

        setName: (state, name) => {
            state.name = name;
        },
        setHiddenColumn: (state, column) => {
            state.hiddenColumn = column;
        }
    },
});

export const { setCenter } = globalSlice.actions;
export const { setUserId } = globalSlice.actions;
export const { setName } = globalSlice.actions;
export const { setHiddenColumn } = globalSlice.actions;

export default globalSlice.reducer;